var modules = modules || {}
var EDIT_TYPE_ALL = 'ALL',
  EDIT_TYPE_FOLLOWING = 'FOLLOWING',
  EDIT_TYPE_OVERRIDE = 'OVERRIDE'
modules.diningeventslideout = (function () {
  var venueLocale, localizedProgram, localizedProgramCapitalized
  var helpers = {
    weekDaysOptions: {
      Sunday: 'S',
      Monday: 'M',
      Tuesday: 'T',
      Wednesday: 'W',
      Thursday: 'TH',
      Friday: 'F',
      Saturday: 'SA',
    },

    shiftCategoryOptions: _.object([
      ['BREAKFAST', 'Breakfast'],
      ['BRUNCH', 'Brunch'],
      ['LUNCH', 'Lunch'],
      ['DAY', 'Day'],
      ['DINNER', 'Dinner'],
      ['LEGACY', 'Night'],
    ]),

    eventDaySection: React.createClass({
      onClickHandler: function (e) {
        var currentTarget = $(e.currentTarget),
          isChecked = $(currentTarget).is(':checked'),
          selectedDay = $(currentTarget).data('additional'),
          currentWeekDate = new Date(this.props.startDay),
          dayName = currentWeekDate.toLocaleString('default', { weekday: 'long' }),
          dayIndex = _.indexOf(_.keys(helpers.weekDaysOptions), dayName)
        currentWeekDate.addDays(-dayIndex)
        var dateLookup = _.object(
            _.times(7, function (i) {
              var tuple = [currentWeekDate.toLocaleString('default', { weekday: 'long' }), formatDate(currentWeekDate)]
              currentWeekDate.addDays(1)
              return tuple
            })
          ),
          date = dateLookup[selectedDay]
      },

      render: function () {
        var postInputCss = {
            clear: 'both',
            margin: 0,
            border: 0,
            color: '#000 !important',
            width: 14,
            backgroundColor: 'transparent !important',
          },
          divCss = {
            width: 55,
          }

        var options = _.map(
          helpers.weekDaysOptions,
          function (value, key) {
            return (
              <modules.formElements.create
                type="checkbox"
                label={value}
                key={key}
                data-name={key}
                name="days"
                value={value}
                dataAdditional={key}
                checked={_.contains(this.props.selectedDays, value)}
                validator-type="not_empty"
                postLabelStyle={postInputCss}
                inputCss={_.extend({ marginLeft: 4 }, { border: 0 })}
                id={'id_event_' + key}
                style={divCss}
                preInputStyle={{ position: 'relative', left: 0, border: '1px solid #ccc', padding: 2 }}
                validator="not_empty"
                onClickHandler={this.onClickHandler}
                validator_message="Please select a day"
              />
            )
          },
          this
        )

        return <div className="radioset">{options}</div>
      },
    }),

    eventEventTitle: React.createClass({
      render: function () {
        return (
          <div className="inline schedule-name" style={{}}>
            <modules.formElements.create
              name="title"
              validator="not_empty"
              labelText="Title"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              required="True"
              value={this.props.value}
              validator_message={'Please enter the ' + localizedProgram + ' title'}
              inputCss={{ width: '372px' }}
            />
          </div>
        )
      },
    }),

    inlinedDropDown: React.createClass({
      getInitialState: function () {
        return {
          selectedList: this.props.initialList || [],
          deactive_groups: [],
        }
      },

      onMouseOver: function (e) {
        $(e.currentTarget).css('background-color', '#DDDDDD')
      },

      onMouseOut: function (e) {
        $(e.currentTarget).css('background-color', '#FFFFFF')
      },

      componentDidUpdate: function (prevProps, prevState) {
        if (this.props.propagationHandler) {
          this.props.propagationHandler(this.state.selectedList)
        }
      },

      flipSingle: function (e) {
        if ($(e.currentTarget).find('input[type=checkbox]').is(':checked')) {
          this.onRemove(e)
        } else {
          this.onAdd(e)
        }
        e.nativeEvent.preventDefault()
      },

      onAdd: function (e) {
        var id = $(e.currentTarget).attr('data-id'),
          value = $(e.currentTarget).data('value'),
          item = _.find(this.state.selectedList, { id: id })
        if (!item) {
          var selectedList = this.state.selectedList,
            selectedItem = _.find(this.props.flattenedOptions, { id: id })
          if (this.props.limit && _.size(selectedList) == this.props.limit) {
            selectedList.pop()
          }
          if (selectedItem) {
            selectedList.push(selectedItem)
            this.setState({ selectedList: selectedList })
          }
        }
      },

      addOrRemoveAll: function (isChecked, e) {
        var group = $(e.currentTarget).attr('data-group'),
          selectedItems = _.where(this.props.flattenedOptions, { group: group }),
          selectedIds = _.pluck(selectedItems, 'id'),
          existingGroupIds = _.pluck(_.where(this.state.selectedList, { group: group }), 'id'),
          newItems = _.filter(this.props.flattenedOptions, function (option) {
            return _.contains(selectedIds, option['id'])
          }),
          existingListSansCurrentGroup = _.filter(this.state.selectedList, function (option) {
            return !_.contains(existingGroupIds, option['id'])
          })
        if (!isChecked) {
          this.setState({ selectedList: existingListSansCurrentGroup.concat(newItems) })
        } else {
          this.setState({ selectedList: existingListSansCurrentGroup })
        }
      },

      onRemoveAll: function (e) {
        var group = $(e.currentTarget).attr('data-group'),
          existingGroupIds = _.pluck(_.where(this.state.selectedList, { group: group }), 'id')
        if (existingGroupIds) {
          var selectedList = _.filter(this.state.selectedList, function (option) {
            return !_.contains(existingGroupIds, option['id'])
          })
          this.setState({ selectedList: selectedList })
        }
        e.stopPropagation()
      },

      onRemove: function (e) {
        var id = $(e.currentTarget).attr('data-id'),
          selectedItem = _.find(this.state.selectedList, { id: id })
        if (selectedItem) {
          var selectedList = this.state.selectedList,
            index = _.indexOf(selectedList, selectedItem)
          selectedList.splice(index, 1)
          this.setState({ selectedList: selectedList })
        }
        e.stopPropagation()
      },

      onClick: function () {
        if (!this.props.disabled) {
          $(this.getDOMNode()).find('.utildrop').css('display', 'block')
        }
      },

      generateOption: function (item, key, isChecked, type) {
        return (
          <div
            key={key}
            style={{ cursor: 'pointer', padding: 5, fontFamily: 'Roboto', fontSize: 14, color: isChecked ? '#555555' : '#989898' }}
            onClick={type == 'All' ? _.partial(this.addOrRemoveAll, isChecked) : this.flipSingle}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            data-value={item['value']}
            data-type={item['type']}
            data-group={item['group']}
            data-id={item['id']}
          >
            <modules.formElements.create
              key={'checkbox_' + key}
              type="checkbox"
              data-value={item['value']}
              data-type={item['type']}
              data-id={item['id']}
              checked={isChecked}
              inputCss={{ width: 0, padding: 2, margin: 3, height: 0 }}
              preInputStyle={{ height: 9, width: 9, backgroundSize: 8 }}
              style={{ clear: 'both' }}
              labelStyle={{ margin: 0 }}
            />
            {item['value']}
          </div>
        )
      },

      onExpansionClickHandler: function (e) {
        var selectedGroup = $(e.currentTarget).attr('data-group'),
          deactiveGroups = this.state.deactive_groups,
          index = _.indexOf(deactiveGroups, selectedGroup)
        if (index > -1) {
          deactiveGroups.splice(index, 1)
        } else {
          deactiveGroups.push(selectedGroup)
        }
        this.setState({ deactive_groups: deactiveGroups })
      },

      generateGroupedOptions: function (selectedIds, selectedGroupIds, groupedOptionObj) {
        var listOptions = groupedOptionObj.options.map(function (item, key) {
            return this.generateOption(item, key, _.contains(selectedIds, item['id']))
          }, this),
          shouldShow = !_.contains(this.state.deactive_groups, groupedOptionObj.label),
          parentHeader = groupedOptionObj.label + ' (' + selectedGroupIds.length + '/' + groupedOptionObj.options.length + ')',
          allOptionLabel = 'All ' + groupedOptionObj.label + ' (' + groupedOptionObj.options.length + ')'

        return (
          <div key={groupedOptionObj.label} style={{ borderTop: '1px solid #F1F1F1' }}>
            <div>
              <div
                onClick={this.onExpansionClickHandler}
                data-group={groupedOptionObj.label}
                style={{ cursor: 'pointer', fontFamily: 'Roboto', textTransform: 'uppercase', padding: '5px 8px', fontSize: 10 }}
              >
                <span style={{ color: '#50ABA6' }}>{shouldShow ? String.fromCharCode(8212) + ' ' : '+ '}</span>
                <span style={{ color: '#C1C1C1' }}>{parentHeader}</span>
              </div>
              {this.generateOption(
                {
                  type: 'All',
                  id: 'All',
                  group: groupedOptionObj.label,
                  value: allOptionLabel,
                },
                'All',
                selectedGroupIds.length == groupedOptionObj.options.length,
                'All'
              )}
            </div>
            {shouldShow ? listOptions : null}
          </div>
        )
      },

      render: function () {
        var selectedIds = _.pluck(this.state.selectedList, 'id')
        var renderOptions = this.props.options.map(function (item, key) {
          if (item.type == 'grouped') {
            var selectedGroupIds = _.pluck(_.where(this.state.selectedList, { group: item['label'] }), 'id')
            return this.generateGroupedOptions(selectedIds, selectedGroupIds, item)
          } else {
            return this.generateOption(item, key, _.contains(selectedIds, item['id']))
          }
        }, this)
        inlineDisplayOptions = _.filter(
          _.map(
            this.props.options,
            function (item, key) {
              if (item.type == 'grouped') {
                var numSelectedOptions = _.size(_.where(this.state.selectedList, { group: item['label'] }))
                if (numSelectedOptions > 0) {
                  return _.extend({}, item, {
                    total_selected: numSelectedOptions,
                    total_available: _.size(item.options),
                  })
                } else {
                  return undefined
                }
              } else {
                return _.contains(selectedIds, item['id']) ? item : undefined
              }
            },
            this
          )
        )

        return (
          <div data-label={this.props.label} key={this.props.label} className="inline">
            <div className="inline">
              <div
                onClick={this.onClick}
                style={{
                  float: 'left',
                  clear: 'both',
                  width: 372,
                  backgroundColor: this.props.disabled ? '#BBB' : '#FFF',
                  minHeight: 20,
                  padding: 5,
                  marginTop: 10,
                  border: '1px solid #CACACA',
                }}
              >
                <helpers.selectedInputInline
                  disabled={this.props.disabled}
                  name={this.props.name}
                  inlineDisplayOptions={inlineDisplayOptions}
                  selectedList={this.state.selectedList}
                  onRemove={this.onRemove}
                  onRemoveAll={this.onRemoveAll}
                />
              </div>
              <div
                className="utildrop"
                style={{
                  position: 'initial',
                  clear: 'both',
                  display: 'none',
                  width: 382,
                  border: '1px solid #CACACA',
                  color: '#989898',
                  float: 'left',
                  maxHeight: 250,
                  overflowY: 'scroll',
                }}
              >
                {renderOptions}
              </div>
            </div>
          </div>
        )
      },
    }),

    selectedInputInline: React.createClass({
      onMouseOver: function (e) {
        $(e.currentTarget).css('background-color', '#E1E1E1')
        $(e.currentTarget).find('.close').css({ visibility: 'visible' })
      },

      onMouseOut: function (e) {
        $(e.currentTarget).css('background-color', '#F1F1F1')
        $(e.currentTarget).find('.close').css({ visibility: 'hidden' })
      },

      render: function () {
        var potentialOptions = this.props.selectedList.length > 10 ? this.props.inlineDisplayOptions : this.props.selectedList,
          inlinedDisplayData = _.map(
            potentialOptions,
            function (selectedObj, key) {
              var label =
                selectedObj['type'] == 'grouped'
                  ? selectedObj['label'] + ' (' + selectedObj['total_selected'] + '/' + selectedObj['total_available'] + ')'
                  : selectedObj['value']
              return (
                <div
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  className="selected_option"
                  data-id={selectedObj['id']}
                  key={key}
                  data-value={selectedObj['value']}
                  data-type={selectedObj['type']}
                  style={{
                    backgroundColor: '#F1F1F1',
                    color: '#A1A1A1',
                    border: '1px solid #A1A1A1',
                    float: 'left',
                    marginLeft: 5,
                    cursor: 'pointer',
                    marginBottom: 2,
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      float: 'left',
                      padding: '3px 5px',
                      color: '#676767',
                    }}
                  >
                    {label}
                  </div>
                  <div
                    className="close"
                    key={'close_' + key}
                    data-id={selectedObj['id']}
                    data-type={selectedObj['type']}
                    data-group={selectedObj['label']}
                    onClick={selectedObj['type'] != 'grouped' ? this.props.onRemove : this.props.onRemoveAll}
                    style={{ height: '100%', padding: 3, visibility: 'hidden', display: 'block', cursor: 'pointer', float: 'right' }}
                  >
                    x
                  </div>
                </div>
              )
            },
            this
          ),
          inlinedHiddenOptions = _.map(
            this.props.selectedList,
            function (option, key) {
              return (
                <input
                  key={key}
                  type="hidden"
                  disabled={this.props.disabled}
                  value={option['id']}
                  name={option['name'] || this.props.name}
                />
              )
            },
            this
          )
        return (
          <div>
            {inlinedDisplayData}
            {inlinedHiddenOptions}
          </div>
        )
      },
    }),

    eventDaySelection: React.createClass({
      render: function () {
        return (
          <div className="inline group-size" style={{ display: this.props.hidden ? 'none' : 'block' }}>
            <input id="schedule-day" type="hidden" value="2" className="auto -schedule_day" name="schedule_day" />

            <p className="group-label" style={{ color: '#777', top: 0 }}>
              Days of week to apply this {localizedProgram}
              <span className="required">*</span>
            </p>
            <helpers.eventDaySection
              selectedDays={this.props.selectedDays}
              addOverlayCallback={this.props.addOverlayCallback}
              startDay={this.props.startDay}
              disabled={this.props.disabled}
            />
          </div>
        )
      },
    }),

    eventDetailsInputs: React.createClass({
      render: function () {
        var commonCss = {
          inputCss: { position: 'relative', left: 0, float: 'left', border: 0 },
          preLabelStyle: { paddingTop: '10px' },
          preInputStyle: { float: 'left', width: 'auto', height: 'auto', paddingTop: 10 },
          postLabelStyle: { margin: 0, width: 300, paddingTop: 10, paddingLeft: 20 },
        }
        return (
          <div style={{ margin: '0 0 20px' }} className="inline">
            <modules.formElements.create
              name="cuisine"
              labelText="Cuisine"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.cuisine}
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
            />
            <modules.formElements.create
              name="price_point"
              labelText="Price Point"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.price_point}
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
            />
            <modules.formElements.create
              name="description"
              labelText="Description"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.description}
              type="textarea"
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
            />
            <modules.formElements.create
              name="dress_code"
              labelText="Dress code"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.dress_code}
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
              type="textarea"
            />
            <modules.formElements.create
              name="table_holding_policy"
              labelText="Table holding policy"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.table_holding_policy}
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
              type="textarea"
            />
            <modules.formElements.create
              name="spend_policy"
              labelText="Spend policy"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.spend_policy}
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
              type="textarea"
            />
            <modules.formElements.create
              name="child_policy"
              labelText="Child policy"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel={true}
              value={this.props.child_policy}
              style={{ clear: 'both' }}
              inputCss={{ width: '372px' }}
              type="textarea"
            />
          </div>
        )
      },
    }),

    eventShiftCategoriesSelection: React.createClass({
      getInitialState: function () {
        return {
          selected_categories: this.props.selected_shift_categories || [_.first(_.keys(helpers.shiftCategoryOptions))],
        }
      },

      render: function () {
        var category_options = _.map(helpers.shiftCategoryOptions, function (v, k) {
            return {
              type: 'table',
              value: v,
              id: k,
              name: 'shift_categories',
            }
          }),
          selected_shift_categories = _.map(this.props.selected_shift_categories, function (cat) {
            return {
              type: 'table',
              value: helpers.shiftCategoryOptions[cat],
              id: cat,
              name: 'shift_categories',
            }
          })
        return (
          <div className="inline">
            <p className="group-label" style={{ color: '#777' }}>
              Shifts <span className="required">*</span>
            </p>
            <helpers.inlinedDropDown
              ref="shift_categories_dropdown"
              options={category_options}
              flattenedOptions={category_options}
              initialList={selected_shift_categories}
              disabled={this.props.disabled}
              selectedObjs={[]}
            />
          </div>
        )
      },
    }),

    eventEventDateIntervals: React.createClass({
      componentDidUpdate: function () {
        this.postRenderUpdate()
      },

      postRenderUpdate: function () {
        helpers.applyCalendarBehavior('#event-start-date-selector', this.props.startDay)
        helpers.applyCalendarBehavior(
          '#event-end-date-selector',
          this.props.endDay ? this.props.endDay : this.props.startDay,
          undefined,
          this.props.startDay
        )
        $(this.getDOMNode()).find('#event-start-date-selector').prop('disabled', true)
        $(this.getDOMNode()).find('#event-end-date-selector').prop('disabled', this.props.isIndefinite)
        $(this.getDOMNode()).find('#event-start-date-selector-submit').prop('disabled', true)
        $(this.getDOMNode()).find('#event-end-date-selector-submit').prop('disabled', this.props.isIndefinite)

        if (this.props.isIndefinite) {
          $('#event-end-date-selector').val('--')
          $('#event-end-date-selector-submit').val('--')
        }
      },

      componentDidMount: function () {
        this.postRenderUpdate()
      },

      onClickIndefinitely: function (e) {
        if ($(e.currentTarget).is(':checked')) {
          $('#event-end-date-selector').prop('disabled', true)
          $('#event-end-date-selector').val('--')
          $('#event-end-date-selector-submit').prop('disabled', true)
          $('#event-end-date-selector-submit').val('--')
        } else {
          $('#event-end-date-selector').prop('disabled', false)
          $('#event-end-date-selector-submit').prop('disabled', false)
        }
      },

      render: function () {
        return (
          <div>
            <div className="inline" style={{ paddingTop: 10 }}>
              <div className="form-element cal text">
                <p className="group-label" style={{ color: '#777', margin: '10px 0' }}>
                  Start date
                  <span className="required">*</span>
                </p>
                <label htmlFor="event-date-selector">
                  <p className="input" style={{ position: 'relative' }}>
                    <input readOnly="true" id="event-start-date-selector" type="text" name="start_date_cal" srValidate="datepicker_date" />
                    <input id="event-start-date-selector-submit" type="hidden" name="start_date" />
                    <img src={this.props.calendar_image} />
                  </p>
                </label>
                <p className="validator" srValidateError>
                  Please select a start date
                </p>
              </div>
              <div className="form-element cal text">
                <p className="group-label" style={{ color: '#777', margin: '10px 0' }}>
                  End date
                  <span className="required">*</span>
                </p>
                <label htmlFor="event-end-date-selector">
                  <p className="input" style={{ position: 'relative' }}>
                    <input readOnly="true" id="event-end-date-selector" name="end_date_cal" type="text" srValidate="datepicker_date" />
                    <input id="event-end-date-selector-submit" type="hidden" name="end_date" />
                    <img src={this.props.calendar_image} />
                  </p>
                </label>
                <p className="validator" srValidateError>
                  Please select a end date
                </p>
              </div>
            </div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                onClickHandler={this.onClickIndefinitely}
                checked={this.props.isIndefinite ? true : false}
                name="event-indefinitely"
                label="continue indefinite"
                id="event_indefinite"
                preInputStyle={{ backgroundColor: '#FFF' }}
                style={{ paddingLeft: 200 }}
              />
            </div>
          </div>
        )
      },
    }),

    convertToJSDate: function (date, forceMonthDay) {
      var newDate = new Date(date.replace(/-/g, '/'))
      var dateDisplay =
        forceMonthDay || Pmp.Utils.isMonthDayDateFormat()
          ? newDate.getMonth() + 1 + '/' + newDate.getDate()
          : newDate.getDate() + '/' + (newDate.getMonth() + 1)
      return dateDisplay + '/' + newDate.getFullYear()
    },

    singleSection: function (eventObj, calendar_image, media_url, addOverlayCallback, selectedDay, is_recurring, type) {
      var daySelection = (
          <helpers.eventDaySelection
            hidden={_.contains([EDIT_TYPE_OVERRIDE], type)}
            selectedDays={eventObj.selectedDays}
            startDay={eventObj.startDay}
            addOverlayCallback={addOverlayCallback}
          />
        ),
        dateSelection = _.contains([EDIT_TYPE_OVERRIDE], type) ? undefined : (
          <helpers.eventEventDateIntervals
            startDay={EDIT_TYPE_FOLLOWING == type ? selectedDay : eventObj.startDay}
            endDay={eventObj.endDay}
            isIndefinite={eventObj.is_indefinite}
            calendar_image={calendar_image}
          />
        )
      return (
        <div className="section">
          <helpers.eventEventTitle
            value={!eventObj.is_override && type == EDIT_TYPE_OVERRIDE ? eventObj.title + ' ' + selectedDay : eventObj.title}
          />
          {dateSelection}
          {daySelection}
          <helpers.eventShiftCategoriesSelection selected_shift_categories={eventObj.shift_categories} media_url={media_url} />
          <helpers.eventDetailsInputs
            cuisine={eventObj.cuisine}
            price_point={eventObj.price_point}
            description={eventObj.description}
            dress_code={eventObj.dress_code}
            table_holding_policy={eventObj.table_holding_policy}
            spend_policy={eventObj.spend_policy}
            child_policy={eventObj.child_policy}
            media_url={media_url}
          />
        </div>
      )
    },

    applyCalendarBehavior: function (divElement, selectedDate, callback, minDate) {
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
      var jsDate = $.datepicker.parseDate(dateFmt, selectedDate)
      $(divElement).datepicker({
        dateFormat: dateFmt,
        altField: divElement + '-submit',
        altFormat: 'mm/dd/yy',
        minDate: 0,
        closeText: 'Cancel',
        firstDay: 0,
        parentObj: this,
        showButtonPanel: true,

        beforeShow: function () {
          $('#ui-datepicker-div').addClass('customize')
        },
        onSelect: function (dateText, selObj) {
          if (callback) {
            callback(dateText)
          }
        },
        prependZero: function (num) {
          return ('0' + num).slice(-2)
        },
      })
      $(divElement).datepicker('setDate', jsDate, true)
    },

    onMountCallBack: function () {
      'use strict'
    },

    onApiError: function (jqXHR, textStatus, error) {
      try {
        response = JSON.parse(jqXHR.responseText)
        UserNotificationInterop.error(response.msg)
      } catch (e) {
        UserNotificationInterop.error(error)
      }
    },
  }

  var isValidParams = function (id) {
    var validator = new sr.Validator($('#' + id).parent(), 'USD')
    validator.activeFieldAttr = 'data-sr-validate'
    validator.errorDisplayAttr = 'data-sr-validate-error'
    var isValid = validator.validate()
    if (!isValid) {
      // Find the first invalid element
      var firstInvalidElement = _.first(
        _.filter($('.validator'), function (x) {
          return $(x).css('display') == 'block'
        })
      )
      // Activate that tab
      if (firstInvalidElement) {
        $(firstInvalidElement).closest('.content').prev('.section-header-label').trigger('click')
      }
    }

    if ($('[name=shift_categories]').length === 0) {
      UserNotificationInterop.error('Shifts selection is required')
      isValid = false
    }
    return isValid
  }

  var get_data_for_api = function (id, venue_url_key_or_id, eventId) {
    var serializedArray = $('#' + id).serializeArray()
    var days = _.map(_.where(serializedArray, { name: 'days' }), function (t) {
      return t.value
    })
    var daysString = _.isEmpty(days) ? '' : 'days=' + days.join(','),
      // Use the hidden `start_date` (locale-independent) field to get the value
      disabledStartDate = $('#' + id)
        .find('input[name=start_date_cal]')
        .is(':disabled')
        ? $('#' + id)
            .find('input[name=start_date]')
            .val()
        : undefined

    return (
      $.param(_.reject(serializedArray, { name: 'days' })) +
      '&' +
      daysString +
      '&' +
      $.param(
        _.extend(
          {},
          {
            event_id: eventId,
            venue: venue_url_key_or_id,
          },
          disabledStartDate ? { start_date: disabledStartDate } : {}
        )
      )
    )
  }

  var editEvent = function (id, venue_url_key_or_id, event, type, selectedDay, viewCallBackHandler) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }
    var startDate = type == EDIT_TYPE_ALL ? formatDate(new Date()) : selectedDay,
      recurring = type == EDIT_TYPE_FOLLOWING || type == EDIT_TYPE_ALL,
      entire_series = type == EDIT_TYPE_ALL

    if (!isValidParams(id)) {
      return
    }
    $('.button.bottom').find('a').addClass('disabled')

    $.ajax({
      method: 'PUT',
      url: '/api-yoa/dining_event/' + event.id,
      data:
        get_data_for_api(id, venue_url_key_or_id) +
        '&' +
        'from_date=' +
        selectedDay +
        '&' +
        'start_date=' +
        startDate +
        '&' +
        'is_recurring=' +
        recurring +
        '&' +
        'entire_series=' +
        entire_series,
      success: function (response) {
        if (response.status != '200') {
          UserNotificationInterop.error(response.msg)
        } else {
          if ($('#' + id).find('.close a')[0]) {
            viewCallBackHandler(response.data)
            modules.diningEventsWeekViewManager.refresh(selectedDay)
          }
        }
      },
      error: helpers.onApiError,
      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        parseDiningEvents(venue_url_key_or_id)
      },
    })
  }

  var addEvent = function (id, venue_url_key_or_id, selectedDay, viewCallBackHandler, event) {
    if ($(event.currentTarget).hasClass('disabled')) {
      return
    }
    if (!isValidParams(id)) {
      return
    }
    $(event.currentTarget).addClass('disabled')

    $.ajax({
      method: 'POST',
      url: '/api-yoa/dining_event',
      data: get_data_for_api(id, venue_url_key_or_id) + '&from_date=' + selectedDay,
      success: function (response) {
        if (response.status != '200') {
          UserNotificationInterop.error(response.msg)
        } else {
          if ($('#' + id).find('.close a')[0]) {
            var start_date = $('#' + id)
              .find('[name=start_date]')
              .val()
            viewCallBackHandler(response.data.event)
            modules.diningEventsWeekViewManager.refresh(start_date)
          }
        }
      },
      error: helpers.onApiError,
      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        parseDiningEvents(venue_url_key_or_id)
      },
    })
  }

  var editSlide = function (
    id,
    venue_url_key_or_id,
    media_url,
    selectedDay,
    event,
    addOverlayHandler,
    onCloseClickHandler,
    viewCallBackHandler,
    is_recurring,
    type,
    headerLabel
  ) {
    var start_date = event.is_override ? event.date : event.start_date
    var end_date = event.is_override ? event.date : event.end_date
    var calendar_image = media_url + 'images/icons/calendar-negative.png',
      startDay = helpers.convertToJSDate(start_date),
      endDay = end_date ? helpers.convertToJSDate(end_date) : undefined,
      eventObj = _.extend(
        {},
        {
          selectedDays: modules.weekViewManager.getSelectedDays(event.day_of_week),
          startDay: startDay,
          endDay: endDay,
        },
        event
      )

    var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    var selDt = $.datepicker.parseDate('mm/dd/yy', selectedDay)
    var selectedDayIntl = $.datepicker.formatDate(dateFmt, selDt)

    modules.slideout.render(
      'edit',
      [
        {
          label: localizedProgramCapitalized + ' Details',
          content: _.partial(
            helpers.singleSection,
            eventObj,
            calendar_image,
            media_url,
            addOverlayHandler,
            selectedDayIntl,
            is_recurring,
            type
          ),
        },
      ],
      headerLabel,
      <div
        className="button bottom"
        style={{
          color: '#888',
          padding: '1vh',
          borderTop: '1px solid #CACACA !important',
        }}
      >
        <a
          style={{ maxHeight: '38px' }}
          onClick={_.partial(editEvent, id, venue_url_key_or_id, event, type, selectedDay, viewCallBackHandler)}
        >
          <span>Save</span>
        </a>
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler
    )
  }

  var deleteEvent = function (id, eventId, name, type, selectedDay, event) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }
    $('.button.bottom').find('a').addClass('disabled')

    var deletedDate = type == EDIT_TYPE_ALL ? formatDate(new Date()) : selectedDay,
      recurring = type == EDIT_TYPE_FOLLOWING || type == EDIT_TYPE_ALL,
      entire_series = type == EDIT_TYPE_ALL,
      params = _.extend(
        {},
        {
          date: deletedDate,
          from_date: selectedDay,
          is_recurring: recurring,
          event_id: eventId,
          venue: Pmp.Manager.Global._url_key_or_id,
          entire_series: entire_series,
        }
      )
    $.ajax({
      method: 'DELETE',
      url: '/api-yoa/dining_event/' + eventId + '?' + $.param(params),
      success: function (response) {
        if (response.status != '200') {
          UserNotificationInterop.error(response.msg)
        } else {
          if ($('#' + id).find('.close a')[0]) {
            $('#' + id)
              .find('.close a')[0]
              .click()
            modules.diningEventsWeekViewManager.refresh(selectedDay)
          }
        }
      },
      error: helpers.onApiError,
      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        parseDiningEvents(name)
      },
    })
  }

  var parseDiningEvents = function (urlKey) {
    var date = new Date()
    var start_date = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    date.setDate(date.getDate() + 30)
    var end_date = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()

    $.ajax({
      method: 'GET',
      url: '/api-yoa/dining_event',
      data: {
        venue: urlKey,
        start_date: start_date,
        end_date: end_date,
      },
      success: function (response) {
        var daily_programme = response.data.events
        var has_daily_programme = false
        for (var key in daily_programme) {
          if (daily_programme[key].length) {
            has_daily_programme = true
            break
          }
        }
        attribute_params = { daily_programme: has_daily_programme }
        CustomerSuccessTracker.setAccountAttributes(attribute_params)
      },
      error: helpers.onApiError,
    })
  }

  var recurringDecisionOverlay = function (text, selectedDay, endDay, name, onOverrideCallBk, onRecurringSomeCallBk, onRecurringAllCallBk) {
    const dateFormat = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    const selectedDate = $.datepicker.parseDate('mm/dd/yy', selectedDay)
    const selectedDayIntl = $.datepicker.formatDate(dateFormat, selectedDate)

    const today = new Date()
    const todayIntl = $.datepicker.formatDate(dateFormat, today)

    const textInPast = text === 'Delete' ? 'deleted' : 'edited'
    const nameTruncated = name.substring(0, 18)
    const headerText = `${text} ${name} ${localizedProgramCapitalized}`
    const additionalText = endDay ? `ending on ${endDay}` : ''
    const contentText = `This ${localizedProgram} is a part of a series${
      additionalText ? ' ' + additionalText : ''
    }. What would you like to do?`
    const untilEndText = endDay ? ` <strong>until end on ${endDay}</strong>` : ``

    const overrideButtonText = text === 'Delete' ? `Delete ${selectedDayIntl} only` : `Override ${selectedDayIntl} only`
    const overrideDesc = `All other days in ${nameTruncated} will remain unchanged`
    const followingButtonText = `${text} from ${selectedDayIntl} onwards`
    const followingDesc = `${nameTruncated} will be ${textInPast} from <strong>selected date</strong> (${selectedDayIntl}) onwards${untilEndText}`
    const followingSubdesc = `*Single-day overrides will remain unchanged`
    const allButtonText = `${text} all days from today onwards`
    const allDesc = `${nameTruncated} will be ${textInPast} from <strong>today</strong> (${todayIntl}) onwards${untilEndText}`
    const allSubdesc = `*Single-day overrides will remain unchanged`

    const closeLink = $('<a/>').css({ cursor: 'pointer', float: 'right', fontSize: 20, marginTop: '-15px' }).html('&times;')
    const header = $("<div class='header' />")
      .css({
        height: '26px',
        padding: '5px',
        paddingLeft: 15,
        color: '#000',
        marginTop: '10px',
        fontSize: '15px',
        borderBottom: '1px solid lightgrey',
        textTransform: 'uppercase',
      })
      .html(
        $("<div class='text' style='float: left; max-width: 80%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis'/>").sext(
          headerText
        )
      )
      .append(closeLink)
    const bodyHeader = $("<div class='body-text' />")
      .css({
        height: '20px',
        marginTop: '10px',
        marginLeft: '15px',
        textAlign: 'left',
        color: 'black',
        fontSize: '12px',
      })
      .html(contentText)
    const bodyButton1 = $("<div class='body-button recurring-override-button' />").html(
      `<div class="button">${overrideButtonText}</div><div class="desc">${overrideDesc}</div>`
    )
    const bodyButton2 = $("<div class='body-button recurring-following-button' />").html(
      `<div class="button">${followingButtonText}</div><div class="desc2">${followingDesc}<br /><span>${followingSubdesc}</span></div>`
    )
    const bodyButton3 = $("<div class='body-button recurring-all-button' />").html(
      `<div class="button">${allButtonText}</div><div class="desc2">${allDesc}<br /><span>${allSubdesc}</span></div>`
    )
    const nevermind = $("<div class='footer' />").html("<div class='button'>Nevermind</div>")

    let body = $('<div/>').append(header).append(bodyHeader).append(bodyButton1)
    if (selectedDate.setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0)) {
      body = body.append(bodyButton2)
    }
    body = body.append(bodyButton3).append(nevermind)

    // Apply additional css
    $(body).find('.button').css({
      backgroundColor: '#EEE',
      width: '100px',
      padding: '10px 0px',
      cursor: 'pointer',
    })
    $(body).find('.body-button').css({ display: 'flex', flexDirection: 'row', marginRight: '15px', minHeight: '42px' })
    $(body).find('.body-button .button').css({ width: '200px', minWidth: '200px', height: '100%', marginLeft: '15px', color: '#666' })
    $(body).find('.body-button .desc2 span').css({ fontSize: '10px' })
    $(body).find('.footer .button').css({ float: 'right', marginRight: '10px', marginBottom: '10px' })
    $(body).find('.body-button').css({ overflow: 'hidden', paddingTop: '10px' })
    $(body).find('.body-button .desc').css({ textAlign: 'left', marginLeft: '10px', padding: '10px 0px', color: '#000' })
    $(body).find('.body-button .desc2').css({ textAlign: 'left', marginLeft: '10px', color: '#000' })
    $(body).find('.body-button, .footer').css({ fontSize: '12px', color: '#666' })
    $(body).find('.footer').css({ marginTop: '10px' })

    // Event handling
    var closeHandler = function (e) {
      $(e.currentTarget).closest('.blocker').remove()
    }
    $(closeLink).on('click', closeHandler)
    $(nevermind).find('.button').on('click', closeHandler)
    $(bodyButton1)
      .find('.button')
      .on('click', function (e) {
        onOverrideCallBk(e)
        closeHandler(e)
      })
    $(bodyButton2)
      .find('.button')
      .on('click', function (e) {
        onRecurringSomeCallBk(e)
        closeHandler(e)
      })
    $(bodyButton3)
      .find('.button')
      .on('click', function (e) {
        onRecurringAllCallBk(e)
        closeHandler(e)
      })

    return $("<div class='modal' />")
      .css({
        width: '600px',
        height: 'fit-content',
        position: 'fixed',
        top: '50%',
        'font-family': 'Roboto',
        'font-weight': 'normal',
        'font-style': 'normal',
        color: '#999',
        left: '50%',
        'margin-top': '-100px',
        'margin-left': '-150px',
        'background-color': '#FFFFFF',
        'border-radius': '2px',
        'text-align': 'center',
        'z-index': 1001,
      })
      .append(body)
  }

  var deleteDecisionBlock = function (id, name, eventId, recurring, event_name, selectedDay, startDay, endDay) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }

    if (!recurring) {
      deleteEvent(id, eventId, name, EDIT_TYPE_OVERRIDE, selectedDay)
    } else if (startDay === endDay) {
      // One day recurring event, just delete entirely
      deleteEvent(id, eventId, name, EDIT_TYPE_ALL, selectedDay)
    } else {
      var blocker = $('.blocker').size()
        ? $('.blocker')
        : $("<div class='blocker'/>")
            .css({
              'z-index': 1000,
              position: 'fixed',
              width: '100%',
              height: '100%',
              background: 'rgba(1,1,1,0.5)',
              left: 0,
              top: 0,
            })
            .append(
              recurringDecisionOverlay(
                'Delete',
                selectedDay,
                endDay,
                event_name,
                _.partial(deleteEvent, id, eventId, name, EDIT_TYPE_OVERRIDE, selectedDay),
                _.partial(deleteEvent, id, eventId, name, EDIT_TYPE_FOLLOWING, selectedDay),
                _.partial(deleteEvent, id, eventId, name, EDIT_TYPE_ALL, selectedDay)
              )
            )
            .appendTo('body')
    }
  }

  var editDecisionBlock = function (invokeEditSlideOut, recurring, selectedDay, name, startDay, endDay) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }

    if (!recurring) {
      invokeEditSlideOut(EDIT_TYPE_OVERRIDE, name)
    } else if (startDay === endDay) {
      // One day recurring event, just edit entirely
      invokeEditSlideOut(EDIT_TYPE_ALL, name)
    } else {
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
      var selDt = $.datepicker.parseDate('mm/dd/yy', selectedDay)
      var selectedDayIntl = $.datepicker.formatDate(dateFmt, selDt)

      var blocker = $('.blocker').size()
        ? $('.blocker')
        : $("<div class='blocker'/>")
            .css({
              'z-index': 1000,
              position: 'fixed',
              width: '100%',
              height: '100%',
              background: 'rgba(1,1,1,0.5)',
              left: 0,
              top: 0,
            })
            .append(
              recurringDecisionOverlay(
                'Edit',
                selectedDay,
                endDay,
                name,
                _.partial(invokeEditSlideOut, EDIT_TYPE_OVERRIDE, 'OVERRIDE (' + name + ') - ' + selectedDayIntl),
                _.partial(invokeEditSlideOut, EDIT_TYPE_FOLLOWING, 'EDIT (' + name + ') - FROM ' + selectedDayIntl + ' onwards'),
                _.partial(invokeEditSlideOut, EDIT_TYPE_ALL, 'EDIT (' + name + ') - ' + ' ALL DAYS')
              )
            )
            .appendTo('body')
    }
  }

  var viewSlide = function (id, venue_url_key_or_id, media_url, selectedDay, addOverlayHandler, onCloseClickHandler, event) {
    var start_date = event.is_override ? event.date : event.start_date
    var end_date = event.is_override ? event.date : event.end_date
    var startDay = helpers.convertToJSDate(start_date),
      endDay = end_date ? helpers.convertToJSDate(end_date) : undefined,
      is_recurring = !event.is_override || !(startDay == endDay),
      isSelectedDateInPast = new Date(selectedDay) < Pmp.Manager.Global._venue_today_date,
      onEditMouseOver = function (e) {
        $(e.currentTarget).css({ 'background-color': '#e7e7e7' })
      },
      onEditMouseOut = function (e) {
        $(e.currentTarget).css({ 'background-color': '#FFFFFF' })
      },
      invokeEditMode = _.partial(
        editSlide,
        id,
        venue_url_key_or_id,
        media_url,
        selectedDay,
        event,
        addOverlayHandler,
        onCloseClickHandler,
        _.partial(viewSlide, id, venue_url_key_or_id, media_url, selectedDay, addOverlayHandler, onCloseClickHandler),
        is_recurring
      )
    var bottom = isSelectedDateInPast ? undefined : event.archived_date ? (
      <div>
        <p
          className="button bottom"
          style={{
            color: '#888',
            width: '100%',
            padding: 0,
          }}
        >
          <a
            onClick={_.partial(deleteEvent, id, venue_url_key_or_id, event, selectedDay, startDay)}
            style={{
              float: 'left',
              width: '98%',
              backgroundColor: '#FFF',
              color: '#000',
              padding: 0,
              borderRadius: 0,
              borderLeft: '1px solid #eee',
            }}
          >
            <span>Delete</span>
          </a>
        </p>
      </div>
    ) : (
      <div>
        <p
          className="button bottom"
          style={{
            color: '#888',
            width: '100%',
            padding: 0,
          }}
        >
          <a
            onMouseOver={onEditMouseOver}
            onMouseOut={onEditMouseOut}
            onClick={_.partial(editDecisionBlock, invokeEditMode, is_recurring, selectedDay, event.title, startDay, endDay)}
            style={{
              float: 'left',
              width: '49%',
              backgroundColor: '#FFF',
              color: '#000',
              padding: 0,
            }}
          >
            <span>Edit</span>
          </a>
          <a
            onMouseOver={onEditMouseOver}
            onMouseOut={onEditMouseOut}
            onClick={_.partial(
              deleteDecisionBlock,
              id,
              venue_url_key_or_id,
              event.id,
              is_recurring,
              event.title,
              selectedDay,
              startDay,
              endDay
            )}
            style={{
              float: 'left',
              width: '49%',
              backgroundColor: '#FFF',
              color: '#000',
              padding: 0,
              borderRadius: 0,
              borderLeft: '1px solid #eee',
            }}
          >
            <span>Delete</span>
          </a>
        </p>
      </div>
    )

    modules.slideout.render(
      'view',
      [
        {
          content: function () {
            var daysSelection =
              event.start_date != event.end_date ? (
                <modules.slideout.viewModeElement
                  identifier="Days"
                  value={modules.weekViewManager.getSelectedDays(event.day_of_week).join(', ')}
                />
              ) : undefined
            return (
              <div className="section">
                <modules.slideout.viewModeHeader label={localizedProgramCapitalized + ' Details'} />
                <modules.slideout.viewModeElement identifier="Title" value={event.title} />
                <modules.slideout.viewModeElement identifier="Date range" value={startDay + ' - ' + (endDay ? endDay : 'Indefinite')} />
                {daysSelection}
                <modules.slideout.viewModeElement
                  identifier="Shifts"
                  value={_.map(event.shift_categories, function (cat) {
                    return helpers.shiftCategoryOptions[cat]
                  }).join(', ')}
                />
                <modules.slideout.viewModeElement identifier="Cuisine" value={event.cuisine} />
                <modules.slideout.viewModeElement identifier="Price Point" value={event.price_point} />
                <modules.slideout.viewModeElement identifier="Description" value={event.description} />
                <modules.slideout.viewModeElement identifier="Dress code" value={event.dress_code} />
                <modules.slideout.viewModeElement identifier="Table holding policy" value={event.table_holding_policy} />
                <modules.slideout.viewModeElement identifier="Spend policy" value={event.spend_policy} />
                <modules.slideout.viewModeElement identifier="Child policy" value={event.child_policy} />
              </div>
            )
          },
        },
      ],
      event.title,
      { bottom },
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler
    )
  }

  var formatDate = function (date) {
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
  }

  var addSlide = function (id, venue_url_key_or_id, media_url, title, categories, selectedDay, addOverlayHandler, onCloseClickHandler) {
    var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    var selectedDate = new Date(selectedDay),
      selectedDays = helpers.weekDaysOptions[selectedDate.toLocaleString('default', { weekday: 'long' })],
      endDay = $.datepicker.formatDate(dateFmt, new Date(selectedDay).addDays(7))
    var startDay = $.datepicker.formatDate(dateFmt, selectedDate)

    helpers.mode = 'add'
    var calendar_image = media_url + 'images/icons/calendar-negative.png',
      eventObj = {
        title: title,
        startDay: startDay,
        endDay: endDay,
        is_indefinite: true,
        selectedDays: [selectedDays],
        shift_categories: categories,
        cuisine: '',
        price_point: '',
        description: '',
        dress_code: '',
        table_holding_policy: '',
        spend_policy: '',
        child_policy: '',
      }
    modules.slideout.render(
      'edit',
      [
        {
          label: localizedProgramCapitalized + ' Details',
          content: _.partial(helpers.singleSection, eventObj, calendar_image, media_url, addOverlayHandler, selectedDay),
        },
      ],
      'Add ' + localizedProgramCapitalized,
      <div
        className="button bottom"
        style={{
          color: '#888',
          padding: '1vh',
          borderTop: '1px solid #CACACA',
        }}
      >
        <a
          style={{ maxHeight: '38px' }}
          onClick={_.partial(
            addEvent,
            id,
            venue_url_key_or_id,
            selectedDay,
            _.partial(viewSlide, id, venue_url_key_or_id, media_url, selectedDay, addOverlayHandler, onCloseClickHandler)
          )}
        >
          <span>Create {localizedProgramCapitalized}</span>
        </a>
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler
    )
  }

  var init = function (_venue_locale) {
    venueLocale = _venue_locale
    localizedProgram = `program${venueLocale === 'en_GB' ? 'me' : ''}`
    localizedProgramCapitalized = 'P' + localizedProgram.slice(1)
  }

  return {
    init: init,
    view: viewSlide,
    add: addSlide,
    weekDaysOptions: helpers.weekDaysOptions,
  }
})()
